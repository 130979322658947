import { NotificationType } from '@local/web-design-system';
import { Dispatch } from 'redux';
import { CLIENT_SIDE_PAGINATION_LIMIT, DATATABLE_TYPE } from 'state-domains/constants';
import {
    convertToBackendSortKey,
    downloadFile,
    getConfigurationSortOptionsFromCache,
} from 'state-domains/domain/utils';
import { getSessionId } from 'src/utilities';
import { getCachedDataTablePage } from 'src/components/PaginationControls/PaginationControls.utils';
import { GetState } from 'state-domains/types';
import { getFiltersForConfiguration } from 'src/components/Configuration/Filter/Filter.utils';

import {
    ADD_SNACKBAR_NOTIFICATION,
    CLEAR_ALL_CONFIGURATION_ITEMS,
    CLEAR_EVENT_ACTIONS,
    CLEAR_EVENTS,
    CLEAR_JOB_LIST,
    CLEAR_POSTS,
    CREATE_OR_UPDATE_JOB,
    DELETE_POSTS,
    DOWNLOAD_POST_ATTACHMENT,
    LIST_JOBS,
    LOAD_EVENTS,
    LOAD_POSTS,
    SET_JOB_SEARCH_TERM,
} from '../../../types/actionTypes';
import {
    convertToCamel,
    isValidSecureUrl,
    typeComplete,
    typeFail,
    typePending,
} from '../../../utils';
import { jobShim } from '../shim';
import { EventsObject, Job, JobCompleteEvent, JobType, PostsObject } from '../types';

const loadJobList =
    (userId?: string, statuses = ['all']) =>
    (dispatch: Dispatch) => {
        dispatch({ type: typePending(LIST_JOBS) });
        jobShim.loadJobList(statuses, userId).subscribe({
            next: (jobsList: Job[]) =>
                dispatch({
                    type: typeComplete(LIST_JOBS),
                    payload: jobsList,
                }),
            error: (error: Error) => {
                dispatch({ type: typeFail(LIST_JOBS), payload: { error } });
                dispatch({
                    type: ADD_SNACKBAR_NOTIFICATION,
                    payload: { type: NotificationType.ERROR, message: error },
                });
            },
        });
    };

const loadHelper = (
    type: DATATABLE_TYPE,
    searchTerm: string,
    getState: GetState,
    field = 'name',
) => {
    const userId = getState().user?.id ?? '';
    const subscriptionId = getState().user?.selected?.id ?? '';
    const filters = getFiltersForConfiguration(type, userId, subscriptionId);
    if (searchTerm) {
        filters.push({
            type: 'text',
            operator: 'contains',
            values: [searchTerm],
            field,
        });
    }
    const { limit, offset } = getCachedDataTablePage(type, userId, subscriptionId ?? '');
    const sortBy = convertToBackendSortKey(
        getConfigurationSortOptionsFromCache(userId, subscriptionId ?? '', type),
    );
    return { filters, limit, offset, sortBy };
};

const loadEvents =
    (searchTerm = '') =>
    (dispatch: Dispatch, getState: GetState) => {
        dispatch({ type: typePending(LOAD_EVENTS) });
        const { filters, limit, offset, sortBy } = loadHelper(
            DATATABLE_TYPE.EVENTS,
            searchTerm,
            getState,
            'reference_name',
        );
        jobShim.loadEvents(filters, limit, offset, sortBy).subscribe({
            next: (events: { Event: EventsObject[]; count: number }) =>
                dispatch({
                    type: typeComplete(LOAD_EVENTS),
                    payload: events,
                }),
            error: (error: Error) => {
                dispatch({ type: typeFail(LOAD_EVENTS), payload: { error } });
                dispatch({
                    type: ADD_SNACKBAR_NOTIFICATION,
                    payload: { type: NotificationType.ERROR, message: error },
                });
            },
        });
    };

const clearEvents = () => (dispatch: Dispatch) => dispatch({ type: typeComplete(CLEAR_EVENTS) });

const loadPosts =
    (searchTerm = '') =>
    (dispatch: Dispatch, getState: GetState) => {
        dispatch({ type: typePending(LOAD_POSTS) });
        const { filters, limit, offset, sortBy } = loadHelper(
            DATATABLE_TYPE.POSTS,
            searchTerm,
            getState,
            'content',
        );
        jobShim.loadPosts(filters, limit, offset, sortBy).subscribe({
            next: (posts: { Post: PostsObject[]; count: number }) =>
                dispatch({
                    type: typeComplete(LOAD_POSTS),
                    payload: posts,
                }),
            error: (error: Error) => {
                dispatch({ type: typeFail(LOAD_POSTS), payload: { error } });
                dispatch({
                    type: ADD_SNACKBAR_NOTIFICATION,
                    payload: { type: NotificationType.ERROR, message: error },
                });
            },
        });
    };

const clearPosts = () => (dispatch: Dispatch) => dispatch({ type: typeComplete(CLEAR_POSTS) });

const clearJobList = () => (dispatch: Dispatch) => dispatch({ type: typeComplete(CLEAR_JOB_LIST) });

const setJobSearchTerm =
    (searchTerm: string, offset = 0, limit: number = CLIENT_SIDE_PAGINATION_LIMIT) =>
    (dispatch: Dispatch) =>
        dispatch({
            type: typeComplete(SET_JOB_SEARCH_TERM),
            payload: { searchTerm, offset, limit },
        });

const updateJob = (data: string) => (dispatch: Dispatch) => {
    const payload = convertToCamel(JSON.parse(data)) as Job;
    dispatch({ type: CREATE_OR_UPDATE_JOB, payload });
};

const completeJob = (data: string) => {
    const event = convertToCamel(JSON.parse(data)) as JobCompleteEvent;
    if (
        event.detail === JobType.Export ||
        event.detail === JobType.ListExport ||
        event.detail === JobType.TableExport
    ) {
        const sessionId = getSessionId();

        if (isValidSecureUrl(event.referenceDetail) && event.detail1 === sessionId) {
            downloadFile(event.referenceDetail);
        }
    }
};

const deletePost = (id: string) => (dispatch: Dispatch) => {
    dispatch({ type: typePending(DELETE_POSTS) });
    jobShim.deletePosts(id).subscribe({
        next: () => {
            dispatch({ type: typeComplete(DELETE_POSTS), payload: { shouldReload: true } });
            dispatch({ type: CLEAR_ALL_CONFIGURATION_ITEMS });
        },
        error: (error: Error) => {
            dispatch({ type: typeFail(DELETE_POSTS), payload: { error } });
        },
    });
};

const clearEventActions = () => (dispatch: Dispatch) => {
    dispatch({ type: CLEAR_EVENT_ACTIONS });
};

const downloadPostAttachment =
    (postId: string, attachmentId: string, attachmentName: string) => (dispatch: Dispatch) => {
        dispatch({ type: typePending(DOWNLOAD_POST_ATTACHMENT) });
        jobShim.downloadPostAttachment(postId, attachmentId).subscribe({
            next: (blob) => {
                const url = window.URL.createObjectURL(blob);
                downloadFile(url, attachmentName);
                dispatch({ type: typeComplete(DOWNLOAD_POST_ATTACHMENT) });
            },
            error: (error: Error) => {
                dispatch({ type: typeFail(DOWNLOAD_POST_ATTACHMENT), payload: { error } });
            },
        });
    };

export const actions = {
    loadJobList,
    loadEvents,
    clearEvents,
    loadPosts,
    clearPosts,
    updateJob,
    clearJobList,
    setJobSearchTerm,
    completeJob,
    deletePost,
    clearEventActions,
    downloadPostAttachment,
};
